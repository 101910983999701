import React, { Component } from 'react';
import Tree from 'react-d3-tree';
import Switch from './components/Switch';
import './App.css';

import orgChartJson from './data/family.json';

const countNodes = (count = 0, n) => {
  // Count the current node
  count += 1;

  // Base case: reached a leaf node.
  if (!n.children) {
    return count;
  }

  // Keep traversing children while updating `count` until we reach the base case.
  return n.children.reduce((sum, child) => countNodes(sum, child), count);
};

class App extends Component {
  constructor() {
    super();

    this.addedNodesCount = 0;
    this.toggleCenterNodes = this.toggleCenterNodes.bind(this);
    this.state = {
      data: orgChartJson,
      totalNodeCount: countNodes(0, Array.isArray(orgChartJson) ? orgChartJson[0] : orgChartJson),
      orientation: 'vertical',
      dimensions: undefined,
      centeringTransitionDuration: 800,
      translateX: 200,
      translateY: 300,
      collapsible: true,
      shouldCollapseNeighborNodes: false,
      initialDepth: undefined,
      depthFactor: undefined,
      zoomable: true,
      draggable: true,
      zoom: 1,
      scaleExtent: { min: 0.1, max: 1 },
      separation: { siblings: 2, nonSiblings: 2 },
      nodeSize: { x: 200, y: 200 },
      enableLegacyTransitions: false,
      transitionDuration: 500,
      styles: {
        nodes: {
          node: {
            circle: {
              fill: '#52e2c5',
            },
            attributes: {
              stroke: '#000',
            },
          },
          leafNode: {
            circle: {
              fill: 'transparent',
            },
            attributes: {
              stroke: '#000',
            },
          },
        },
      },
    };
  }

  setTreeData(data) {
    this.setState({
      data,
      totalNodeCount: countNodes(0, Array.isArray(data) ? data[0] : data),
    });
  }

  setLargeTree(data) {
    this.setState({
      data,
      transitionDuration: 0,
    });
  }

  toggleCenterNodes(evt) {
    console.log(evt);
    if (this.state.dimensions !== undefined) {
      this.setState({
        dimensions: undefined,
      });
    } else {
      if (this.treeContainer) {
        const { width, height } = this.treeContainer.getBoundingClientRect();
        this.setState({
          dimensions: {
            width,
            height,
          },
        });
      }
    }
  }

  render() {
    return (
      <div className="App">
        <div className="demo-container">
          <div className="column-right">
            <div className="tree-stats-container">
              Total members in tree: {this.state.totalNodeCount}
            </div>
            <h4 className="prop">
                  Center Click
                </h4>
                <Switch
                  name="centerNodesBtn"
                  checked={this.state.dimensions !== undefined}
                  onChange={this.toggleCenterNodes}
                />
            <div ref={tc => (this.treeContainer = tc)} className="tree-container">
              <Tree
                hasInteractiveNodes
                data={this.state.data}
                renderCustomNodeElement={
                  this.state.renderCustomNodeElement
                    ? rd3tProps => this.state.renderCustomNodeElement(rd3tProps, this.state)
                    : undefined
                }
                rootNodeClassName="node"
                branchNodeClassName="node"
                orientation={this.state.orientation}
                dimensions={this.state.dimensions}
                centeringTransitionDuration={this.state.centeringTransitionDuration}
                translate={{ x: this.state.translateX, y: this.state.translateY }}
                pathFunc='step'
                collapsible={this.state.collapsible}
                initialDepth={this.state.initialDepth}
                zoomable={this.state.zoomable}
                draggable={this.state.draggable}
                zoom={this.state.zoom}
                scaleExtent={this.state.scaleExtent}
                nodeSize={this.state.nodeSize}
                separation={this.state.separation}
                enableLegacyTransitions={this.state.enableLegacyTransitions}
                transitionDuration={this.state.transitionDuration}
                depthFactor={this.state.depthFactor}
                styles={this.state.styles}
                shouldCollapseNeighborNodes={this.state.shouldCollapseNeighborNodes}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
